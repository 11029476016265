import { createContext } from 'react';
import io from 'socket.io-client';
const apiUrl = process.env.REACT_APP_API_BASE_URL_NOTIFICATION

const sockets = io(apiUrl, {
  transports: ['websocket', 'polling'],
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 2000,
});

export const SocketContexts = createContext(sockets);
export { sockets };

const PathConstants = {
    Demo: { path: "demo" },
    Login: { path: "/" },
    LoginPage: { path: "login" },
    Register: { path: "register" },
    Home: { path: "home", Fcode: "HOM" },
    Parties: { path: "parties", Fcode: "PRT" },
    Items: { path: "items", Fcode: "ITM" },
    AddItems: { path: "additems", Fcode: "ITM" },
    SaleInvoices: { path: "saleinvoices", Fcode: "SLN" },
    AddSaleInvoices: { path: "addSaleInvoices", Fcode: "SLN" },
    SaleOrder: { path: "saleorder", Fcode: "SOR" },
    SaleAddOrder: { path: "saleaddorder", Fcode: "SOR" },
    SaleReturn: { path: "salereturn", Fcode: "SRT" },
    AddCreditNote: { path: "addCreditNote", Fcode: "SRT" },
    PurchaseBills: { path: "purchasebills", Fcode: "PRB" },
    AddPurchaseBills: { path: "addPurchaseBills", Fcode: "PRB" },
    PurchaseReturn: { path: "purchasereturn", Fcode: "PUR" },
    AddDebitNote: { path: "addDebitNote", Fcode: "PUR" },
    Expensive: { path: "expensive", Fcode: "EXP" },
    AddExpensive: { path: "expensive/addExpensive", Fcode: "EXP" },
    PaymentIn: { path: "paymentin", Fcode: "PIN" },
    PaymentOut: { path: "paymentout", Fcode: "POU" },
    BankAccounts: { path: "bank_accounts", Fcode: "BAC" },
    CashInHand: { path: "cash_in_hand", Fcode: "CIH" },
    Cheques: { path: "cheques", Fcode: "CHQ" },
    Report: { path: "report", Fcode: "REP" },
    General: { path: "general", Fcode: "GEN" },
    TaxesGst: { path: "taxes_gst", Fcode: "TAX" },
    Print: { path: "prints", Fcode: "PRS" },
    TransactionMessage: { path: "transaction_message", Fcode: "WMS" },
    GeneralMessage: { path: "general_message", Fcode: "GMS" },
    FormMaster: { path: "form_master", Fcode: "FRM" },
    NewUser: { path: "new_user", Fcode: "NUR" },
    UserPermission: { path: "user_permission", Fcode: "UPR" },
    Profile: { path: "profile", Fcode: "PRF" },
    BulkUpdateItems: { path: "bulk_update_items", Fcode: "BUK" },
    SaleEstimate: { path: "sale_estimate", Fcode: "SES" },
    AddSaleEstimate: { path: "add_sale_estimate", Fcode: "ASE" },
    BarcodeGenerator: { path: "barcode_generator", Fcode: "BRG" },
};

export default PathConstants;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { SocketContexts } from '../../components/SocketContextNotification/SocketContextNotification';
import './Notification.scss';
import { decryptLocalData, getTimeAgo } from '../../components/GlobalFunction/GlobalFunction';
import img from '../../assets/img/avatar.webp';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';

const Notification = () => {
    const [messages, setMessages] = useState([]);
    const [unreadCount, setUnreadCount] = useState(
        parseInt(decryptLocalData('unreadCount'), 10) || 0
    );
    const sockets = useContext(SocketContexts);
    
    const op = useRef(null);
 
    useEffect(() => {
        if (sockets) {
            sockets.on('new-message', (newMessage) => {
                setMessages((prevMessages) => [...prevMessages, newMessage]);
                setUnreadCount((prevCount) => {
                    const newCount = prevCount + 1;
                    localStorage.setItem('unreadCount', newCount);
                    return newCount;
                });
            });

            sockets.on('previous-messages', (previousMessages) => {
                setMessages(previousMessages);
            });

            return () => {
                sockets.off('new-message');
                sockets.off('previous-messages');
            };
        }
    }, [sockets]);

    const handlePanelShow = () => {
        setUnreadCount(0);
        localStorage.setItem('unreadCount', '0');
    };

    return (
        <div className="Notification-container">
            <Button
                type="button"
                icon="pi pi-bell"
                onClick={(e) => op.current.toggle(e)}
            >
                {unreadCount > 0 && (
                    <Badge
                        value={unreadCount > 9 ? '9+' : unreadCount}
                        className="notification-badge"
                    />
                )}
            </Button>
            <OverlayPanel
                ref={op}
                className="OverlayPanel-notifictions"
                onShow={handlePanelShow}
            >
                <div className="notifictions">
                    <h1 className="Notification-title">Notifications</h1>
                    <div className="message-board">
                        {messages.length > 0 ? (
                            messages
                                .slice()
                                .reverse()
                                .map((msg, index) => (
                                    <div key={index} className="message-card">
                                        <div className="message-header">
                                            <img
                                                src={img}
                                                alt="Sender Avatar"
                                                className="avatar"
                                            />
                                            <div className="message-info">
                                                <span className="sender-name">{msg.sender}</span>
                                                <span className="timestamp">
                                                    {getTimeAgo(msg.timestamp)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="message-content">
                                            <p className="message-title">{msg.title}</p>
                                            <p>{msg.content}</p>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <React.Fragment>
                                <div className="contain">
                                    <Button type="button" icon="pi pi-bell" />
                                    <h6>No Notifications yet!</h6>
                                    <p> Stay tuned! Notifications about your activity will show up here.
                                    </p>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </OverlayPanel>
        </div>
    );
};

export default Notification;
